// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-brochure-index-jsx": () => import("./../../../src/pages/brochure/index.jsx" /* webpackChunkName: "component---src-pages-brochure-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-brucan-pubs-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-brucan-pubs/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-brucan-pubs-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-easycoffee-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-easycoffee/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-easycoffee-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-fired-up-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-fired-up/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-fired-up-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-flourish-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-flourish/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-flourish-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-foodstory-cafe-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-foodstory-cafe/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-foodstory-cafe-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-golden-lion-group-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-golden-lion-group/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-golden-lion-group-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-hickorys-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-hickorys/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-hickorys-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-itison-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-itison/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-itison-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-manorview-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-manorview/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-manorview-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-maray-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-maray/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-maray-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-mark-sargeant-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-mark-sargeant/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-mark-sargeant-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-mjmk-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-mjmk/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-mjmk-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-mollies-motel-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-mollies-motel/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-mollies-motel-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-pizza-pilgrims-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-pizza-pilgrims/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-pizza-pilgrims-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-restaurant-group-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-restaurant-group/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-restaurant-group-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-riding-house-cafes-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-riding-house-cafes/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-riding-house-cafes-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-roseacre-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-roseacre/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-roseacre-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-sankeys-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-sankeys/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-sankeys-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-signature-pub-group-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-signature-pub-group/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-signature-pub-group-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-the-pizza-room-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-the-pizza-room/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-the-pizza-room-index-jsx" */),
  "component---src-pages-customer-stories-hospitality-the-throckmorton-index-jsx": () => import("./../../../src/pages/customer-stories/hospitality-the-throckmorton/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-hospitality-the-throckmorton-index-jsx" */),
  "component---src-pages-customer-stories-index-jsx": () => import("./../../../src/pages/customer-stories/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-index-jsx" */),
  "component---src-pages-customer-stories-leisure-namco-index-jsx": () => import("./../../../src/pages/customer-stories/leisure-namco/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-leisure-namco-index-jsx" */),
  "component---src-pages-customer-stories-leisure-superbowl-uk-index-jsx": () => import("./../../../src/pages/customer-stories/leisure-superbowl-uk/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-leisure-superbowl-uk-index-jsx" */),
  "component---src-pages-customer-stories-retail-ee-commture-365-index-jsx": () => import("./../../../src/pages/customer-stories/retail-ee-commture365/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-retail-ee-commture-365-index-jsx" */),
  "component---src-pages-customer-stories-retail-o-2-bak-index-jsx": () => import("./../../../src/pages/customer-stories/retail-o2-bak/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-retail-o-2-bak-index-jsx" */),
  "component---src-pages-customer-stories-retail-picsolve-index-jsx": () => import("./../../../src/pages/customer-stories/retail-picsolve/index.jsx" /* webpackChunkName: "component---src-pages-customer-stories-retail-picsolve-index-jsx" */),
  "component---src-pages-demand-forecasting-index-jsx": () => import("./../../../src/pages/demand-forecasting/index.jsx" /* webpackChunkName: "component---src-pages-demand-forecasting-index-jsx" */),
  "component---src-pages-demo-brand-index-jsx": () => import("./../../../src/pages/demo-brand/index.jsx" /* webpackChunkName: "component---src-pages-demo-brand-index-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-free-excel-rota-template-index-jsx": () => import("./../../../src/pages/free-excel-rota-template/index.jsx" /* webpackChunkName: "component---src-pages-free-excel-rota-template-index-jsx" */),
  "component---src-pages-furlough-calculator-index-jsx": () => import("./../../../src/pages/furlough-calculator/index.jsx" /* webpackChunkName: "component---src-pages-furlough-calculator-index-jsx" */),
  "component---src-pages-hospitality-index-jsx": () => import("./../../../src/pages/hospitality/index.jsx" /* webpackChunkName: "component---src-pages-hospitality-index-jsx" */),
  "component---src-pages-hr-index-jsx": () => import("./../../../src/pages/hr/index.jsx" /* webpackChunkName: "component---src-pages-hr-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-eploy-index-jsx": () => import("./../../../src/pages/integrations/eploy/index.jsx" /* webpackChunkName: "component---src-pages-integrations-eploy-index-jsx" */),
  "component---src-pages-integrations-goodtill-sumup-index-jsx": () => import("./../../../src/pages/integrations/goodtill-sumup/index.jsx" /* webpackChunkName: "component---src-pages-integrations-goodtill-sumup-index-jsx" */),
  "component---src-pages-integrations-icrtouch-index-jsx": () => import("./../../../src/pages/integrations/icrtouch/index.jsx" /* webpackChunkName: "component---src-pages-integrations-icrtouch-index-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("./../../../src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-integrations-paycaptain-index-jsx": () => import("./../../../src/pages/integrations/paycaptain/index.jsx" /* webpackChunkName: "component---src-pages-integrations-paycaptain-index-jsx" */),
  "component---src-pages-integrations-resdiary-index-jsx": () => import("./../../../src/pages/integrations/resdiary/index.jsx" /* webpackChunkName: "component---src-pages-integrations-resdiary-index-jsx" */),
  "component---src-pages-integrations-talent-funnel-index-jsx": () => import("./../../../src/pages/integrations/talent-funnel/index.jsx" /* webpackChunkName: "component---src-pages-integrations-talent-funnel-index-jsx" */),
  "component---src-pages-integrations-tenzo-index-jsx": () => import("./../../../src/pages/integrations/tenzo/index.jsx" /* webpackChunkName: "component---src-pages-integrations-tenzo-index-jsx" */),
  "component---src-pages-integrations-xero-index-jsx": () => import("./../../../src/pages/integrations/xero/index.jsx" /* webpackChunkName: "component---src-pages-integrations-xero-index-jsx" */),
  "component---src-pages-landing-christmas-2020-offer-index-jsx": () => import("./../../../src/pages/landing/christmas-2020-offer/index.jsx" /* webpackChunkName: "component---src-pages-landing-christmas-2020-offer-index-jsx" */),
  "component---src-pages-landing-cpl-offer-index-jsx": () => import("./../../../src/pages/landing/cpl-offer/index.jsx" /* webpackChunkName: "component---src-pages-landing-cpl-offer-index-jsx" */),
  "component---src-pages-landing-rbtl-2022-index-jsx": () => import("./../../../src/pages/landing/rbtl-2022/index.jsx" /* webpackChunkName: "component---src-pages-landing-rbtl-2022-index-jsx" */),
  "component---src-pages-leisure-index-jsx": () => import("./../../../src/pages/leisure/index.jsx" /* webpackChunkName: "component---src-pages-leisure-index-jsx" */),
  "component---src-pages-payroll-index-jsx": () => import("./../../../src/pages/payroll/index.jsx" /* webpackChunkName: "component---src-pages-payroll-index-jsx" */),
  "component---src-pages-policies-acceptable-use-policy-index-jsx": () => import("./../../../src/pages/policies/acceptable-use-policy/index.jsx" /* webpackChunkName: "component---src-pages-policies-acceptable-use-policy-index-jsx" */),
  "component---src-pages-policies-data-processing-index-jsx": () => import("./../../../src/pages/policies/data-processing/index.jsx" /* webpackChunkName: "component---src-pages-policies-data-processing-index-jsx" */),
  "component---src-pages-policies-index-jsx": () => import("./../../../src/pages/policies/index.jsx" /* webpackChunkName: "component---src-pages-policies-index-jsx" */),
  "component---src-pages-policies-privacy-policy-index-jsx": () => import("./../../../src/pages/policies/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-index-jsx" */),
  "component---src-pages-policies-security-privacy-architecture-index-jsx": () => import("./../../../src/pages/policies/security-privacy-architecture/index.jsx" /* webpackChunkName: "component---src-pages-policies-security-privacy-architecture-index-jsx" */),
  "component---src-pages-policies-subprocessors-index-jsx": () => import("./../../../src/pages/policies/subprocessors/index.jsx" /* webpackChunkName: "component---src-pages-policies-subprocessors-index-jsx" */),
  "component---src-pages-policies-user-terms-of-service-index-jsx": () => import("./../../../src/pages/policies/user-terms-of-service/index.jsx" /* webpackChunkName: "component---src-pages-policies-user-terms-of-service-index-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-resources-ebook-trends-tech-changing-face-hospitality-index-jsx": () => import("./../../../src/pages/resources/ebook-trends-tech-changing-face-hospitality/index.jsx" /* webpackChunkName: "component---src-pages-resources-ebook-trends-tech-changing-face-hospitality-index-jsx" */),
  "component---src-pages-resources-hospitality-calendar-2021-index-jsx": () => import("./../../../src/pages/resources/hospitality-calendar-2021/index.jsx" /* webpackChunkName: "component---src-pages-resources-hospitality-calendar-2021-index-jsx" */),
  "component---src-pages-resources-hospitality-calendar-2022-index-jsx": () => import("./../../../src/pages/resources/hospitality-calendar-2022/index.jsx" /* webpackChunkName: "component---src-pages-resources-hospitality-calendar-2022-index-jsx" */),
  "component---src-pages-resources-hospitality-calendar-2023-index-jsx": () => import("./../../../src/pages/resources/hospitality-calendar-2023/index.jsx" /* webpackChunkName: "component---src-pages-resources-hospitality-calendar-2023-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-resources-ultimate-2020-calendar-hospitality-retail-index-jsx": () => import("./../../../src/pages/resources/ultimate-2020-calendar-hospitality-retail/index.jsx" /* webpackChunkName: "component---src-pages-resources-ultimate-2020-calendar-hospitality-retail-index-jsx" */),
  "component---src-pages-retail-index-jsx": () => import("./../../../src/pages/retail/index.jsx" /* webpackChunkName: "component---src-pages-retail-index-jsx" */),
  "component---src-pages-rota-scheduling-index-jsx": () => import("./../../../src/pages/rota-scheduling/index.jsx" /* webpackChunkName: "component---src-pages-rota-scheduling-index-jsx" */),
  "component---src-pages-signature-complete-index-jsx": () => import("./../../../src/pages/signature-complete/index.jsx" /* webpackChunkName: "component---src-pages-signature-complete-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */),
  "component---src-pages-time-attendance-index-jsx": () => import("./../../../src/pages/time-attendance/index.jsx" /* webpackChunkName: "component---src-pages-time-attendance-index-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/BlogIndex.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

